
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

var nativeCreateObjectURL = URL.createObjectURL.bind(URL);
var nativeRevokeObjectURL = URL.revokeObjectURL.bind(URL);

function getScreenConstraints(sendSource, callback) {
  var screen_constraints = {};
  // now invoking native getUserMedia API
  callback(null, screen_constraints);
}

document.addEventListener('pause', function () {
  window.appInBackground = true;
  console.log('[custom.function.js] app is running in background');
});
document.addEventListener('resume', function () {
  window.appInBackground = false;
  console.log('[custom.function.js] app is running in foreground');
});

function hasClass(elem, className) {
  return elem.classList.contains(className);
}

function handleFirebaseNotification() {
  try {
    window.FirebasePlugin.onNotificationOpen(function (notification) {
      console.log('[FirebasePlugin.onNotificationOpen]', notification);
      console.log('[appInBackground]', window.appInBackground);
      if (notification.vncPeerJid && window.appInBackground) {
        localStorage.setItem('vncPeerJid', notification.vncPeerJid);
        localStorage.setItem('vncEventType', notification.vncEventType);
        localStorage.setItem('google_sent_time', (new Date().getTime()));
        var myEvent = new CustomEvent("tapNotification", {
          detail: notification
        });
        document.querySelector("body").dispatchEvent(myEvent);
      }
    }, function (error) {
      console.error(error);
    });
  } catch (e) {
  }
}


document.addEventListener('deviceready', function () {
  deviceReadyHandled = true;
  console.log("[custom.function.js] deviceready called...");

  try {
    FastClick.attach(document.body);
    console.log("[custom.function.js] attach FastClick");
  } catch (e) {

  }
  try {
    navigator.noAutolock.disableAutolock();
  } catch (e) {

  }
  if (typeof device !== 'undefined') {
    // handleFirebaseNotification();
    if (device.platform === 'iOS') {
      try {
        //  window.FirebasePlugin.grantPermission();
      } catch (e) {

      }
      window.mobileApp = true;
      document.querySelector('html').classList.remove('safari');
      document.querySelector('html').classList.add('vnctalksafari');
      $(document).on('click', 'a.open-new-window', function (e) {
        var url = $(this).attr('href');
        window.open(url, '_system');
        e.preventDefault();
      });
      // load adapter.js
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "assets/js/adapter-latest.js";
      script.async = false;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
    if (device.platform === 'Android') {
      document.querySelector('html').classList.remove('chrome');
      document.querySelector('html').classList.add('vnctalkchrome');
      var permissions = cordova.plugins.permissions;
      $(document).on('click', 'a.open-new-window', function (e) {
        var url = $(this).attr('href');
        navigator.app.loadUrl(url, {
          openExternal: true
        });
        e.preventDefault();
      });
    }
  }

  document.addEventListener('backbutton', function (e) {
    e.preventDefault();
    if (document.querySelector('#logoutIframe') !== null || document.querySelector('#loginIframe')) {
      navigator.app.exitApp();
    }
  }, false);

  document.addEventListener('exitButton', function () {

    navigator.notification.confirm(
      'Do you want to quit',
      onConfirmQuit,
      'QUIT TITLE',
      'OK,Cancel'
    );

  }, true);

  function onConfirmQuit(button) {
    if (button === '1') {
      navigator.app.exitApp();
      var myEvent = new CustomEvent('logoutXmpp', {
        detail: e.data
      });
      document.querySelector('body').dispatchEvent(myEvent);
    }
  }

  // var HandleIntent = function (intent) {
  //   console.log(intent);
  //   // With intent you'll do almost everything

  //   if (intent.hasOwnProperty('data')) {
  //     // Do something with the File
  //   } else {
  //     // this will happen in getCordovaIntent when the app starts and there's no
  //     // active intent
  //     console.log("The app was opened manually and there is no files to open");
  //   }
  // };

  // Handle the intent when the app is open
  // If the app is running in the background, this function
  // will handle the opened file
  // window.plugins.intent.setNewIntentHandler(HandleIntent);

  // Handle the intent when the app is not open
  // This will be executed only when the app starts or wasn't active
  // in the background
  // window.plugins.intent.getCordovaIntent(HandleIntent, function () {
  //   console.log("Error: Cannot handle open with file intent");
  // });
}, false);

if (typeof Notification !== "undefined") {
  var ChromeNotification = Notification;
}
//
//window.load = function () {
  // var script = document.createElement("script");
  // script.type = "text/javascript";
  // script.async = true;
  // script.src = "assets/js/lib-jitsi-meet.min.js";
  // document.body.appendChild(script);
  // console.log("Load jitsi meet lib");
  // if (navigator.userAgent.toLowerCase().indexOf("vnctalk") !== -1) {
  //   var fastClickScript = document.createElement("script");
  //   fastClickScript.type = "text/javascript";
  //   fastClickScript.async = true;
  //   fastClickScript.src = "assets/js/fastclick.js";
  //   document.body.appendChild(fastClickScript);
  // }
//};
